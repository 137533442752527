import { inject, Injectable } from "@angular/core";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { firstValueFrom, map, Observable } from "rxjs";
import { IApiResponse } from "@core/models/api.response";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  authConfig: AuthConfig = environment.authConfig
  userPermissions: string[] = []
  isPermissionsGet = false
  constructor(private oauthService: OAuthService, private _router: Router, private _activateRouter: ActivatedRoute) { }
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private http = inject(HttpClient)
  adminAuthenticatorIsEnable = false
  get authenticated(): boolean {
    let hasIdToken = this.oauthService.hasValidIdToken();
    let hasAccessToken = this.oauthService.hasValidAccessToken();
    return (hasIdToken && hasAccessToken)
  }

  async checkPermission(permission: string | string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.isPermissionsGet) {
        setTimeout(() => {
          resolve(this.checkPermission(permission))
        }, 100);
      }
      else {
        if (Array.isArray(permission)) {
          resolve(permission.some((perm) => this.userPermissions.includes(perm)))
          return
        }
        resolve(this.userPermissions.includes(permission))
      }
    })
  }

  clearToken() {
    this.oauthService.revokeTokenAndLogout()
  }

  getToken(): string | null {
    return this.oauthService.getAccessToken()
  }

  login() {
    this.configure();
  }

  logout() {
    this.oauthService.stopAutomaticRefresh();
    this.oauthService.revokeTokenAndLogout();
  }

  private configure() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin().then((res: boolean) => {
      this.getUserPermissionList()
      let hasIssuer: boolean = this._activateRouter.snapshot.queryParamMap.has('iss')
      if (res && hasIssuer) {
        this._router.navigate(['/'])
      }
      // this.getAdminSecurity()
    });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  async getUserPermissionList() {
    const url = environment.authConfig.issuer + '/api/v1/admin/api-access-policies/user'
    const permission = await firstValueFrom(this.http.get<IApiResponse<{ permissonNames: string[] }>>(url, { headers: this.headers }))
    this.userPermissions = permission.data?.permissonNames ?? []
    this.isPermissionsGet = true
  }

  private async getAdminSecurity() {
    const url = ''
    const security = (await firstValueFrom(this.http.get<IApiResponse<{ authenticator: boolean }>>(url, { headers: this.headers }))).data
    this.adminAuthenticatorIsEnable = security?.authenticator ?? false

  }
}
